import { Textbox, Point, Canvas, TextStyle as FabricTextStyle, util, CompleteTextStyleDeclaration } from 'fabric'
import Page from '~/editor/page/page'
import { TypesetTextBoundingBox, TypesetTextProps } from '~/editor/typeset-text/typeset-text.interfaces'
import { deleteTypesetText, highlightTypesetText, unselectTypesetText, updateTypesetText, updateTypesetTextInDB } from '~/services/current-document/typeset-texts'
import { CharacterTextStyles, CharacterTextStylesEntry, Gradient, SVGTextProps, TextStyles, TextStylesChange } from '~/types/editor/text-styles'
import { defaultStyles, updateStyles } from '~/services/current-document/text-styles'
import { getAbsoluteGradient } from '~/helpers/absolute-gradient'
import { getFontCSS } from '~/services/fonts/font-css'
import * as Sentry from "@sentry/browser"
import { deepmerge } from 'deepmerge-ts'
import { DocumentProps } from '~/editor/document/document.interfaces'
import Translation from '~/editor/translation/translation'
import { highlightTranslation } from '~/services/current-document/translations'
import { FontData } from '~/local-fonts'
import { mergeCharacterTextStyles } from '~/editor/typeset-text/utils'

class TypesetText{
  id: string
  boundingBox: TypesetTextBoundingBox
  date: string
  page: Page
  selected: boolean
  textObject: Textbox
  strokes: Textbox[]
  shadows: Textbox[]
  text: string
  styles: TextStyles
  charStyles: CharacterTextStyles
  createHistoryEntryOnDelete: boolean
  lastPosition: Point
  lastWidth: number
  settings: DocumentProps['settings']
  translationId: string | null
  translation: Translation | null
  sideviewElementRef: HTMLDivElement | undefined
  skipSelectionEvent: boolean

  constructor({ id, text, styles, charStyles, boundingBox, page, date, settings, translationId }: TypesetTextProps){
    this.id = id
    this.boundingBox = {
      ...boundingBox,
      startPoint: new Point(
        boundingBox.startPoint.x,
        boundingBox.startPoint.y
      )
    }
    this.lastPosition = new Point(
      boundingBox.startPoint.x,
      boundingBox.startPoint.y
    )
    this.lastWidth = boundingBox.absoluteWidth
    this.page = page
    this.selected = false
    this.settings = settings
    this.date = date
    this.strokes = []
    this.shadows = []
    this.text = text
    this.createHistoryEntryOnDelete = true
    this.styles = styles ?? defaultStyles
    this.charStyles = charStyles ?? []
    this.translationId = translationId
    this.translation = null
    this.skipSelectionEvent = false

    const transformProps = this.settings.readOnly ? {
      lockScalingX: true,
      lockScalingY: true,
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      editable: false
    } : null
    this.textObject = new Textbox(text, {
      originX: 'center',
      ...transformProps
    })
    const disabledControls = this.settings.readOnly ? {
      bl: false,
      br: false,
      mb: false,
      ml: false,
      mr: false,
      mt: false,
      tl: false,
      tr: false,
      mtr: false
    } : null
    this.textObject.setControlsVisibility({
      mt: false,
      mb: false,
      ...disabledControls
    })

    this.registerEventListeners()
  }

  getBoundingbox(){
    const pageTop = this.page.image.top
    const pageWidth = this.page.image.getScaledWidth()
    const pageHeight = this.page.image.getScaledHeight()
    const absoluteWidthToDisplay = this.boundingBox.relativeWidth * pageWidth
    const scale = absoluteWidthToDisplay / this.boundingBox.absoluteWidth
    const boundingBox = {
      left: this.boundingBox.startPoint.x * pageWidth,
      top: pageTop + this.boundingBox.startPoint.y * pageHeight,
      width: this.boundingBox.absoluteWidth,
      scaleX: scale,
      scaleY: scale
    }
    return boundingBox
  }

  getDBBoundingBox(){
    const objectTransform = util.saveObjectTransform(this.textObject)
    let objectPoint = new Point(objectTransform.left, objectTransform.top)
    if (this.page.document.selection){
      objectPoint = objectPoint.transform(this.page.document.selection.calcOwnMatrix())
    }
    const scaledWidth = this.textObject.width * this.textObject.getObjectScaling().x
    const boundingBox = {
      startPoint: new Point(
        objectPoint.x / this.page.image.getScaledWidth(),
        (objectPoint.y - this.page.image.top) / this.page.image.getScaledHeight()
      ),
      absoluteWidth: this.textObject.width,
      relativeWidth: scaledWidth / this.page.image.getScaledWidth()
    }
    if(boundingBox.startPoint.x >= 1 || boundingBox.startPoint.y >= 1){
      const overflowDetails = {
        'selection_exists': this.page.document.selection,
        'defaultPoint_(left_top)': new Point(objectTransform.left, objectTransform.top),
        'inSelectionPoint_(calcOwnMatrix)': objectPoint,
        boundingBox
      }
      Sentry.captureException(overflowDetails)
    }
    return boundingBox
  }

  updateStrokes(){
    this.strokes.forEach(stroke => {
      this.page.document.remove(stroke)
    })
    this.strokes = []

    // Apply group transform if object is inside a selection
    const objectTransform = util.saveObjectTransform(this.textObject)
    let objectPoint = new Point(objectTransform.left, objectTransform.top)
    if (this.page.document.selection) {
      objectPoint = objectPoint.transform(this.page.document.selection.calcOwnMatrix())
    }

    this.styles.strokes.forEach(async (stroke) => {
      const textObject = await this.textObject.clone()
      textObject.set({
        selectable: false,
        fill: stroke.color,
        stroke: stroke.color,
        strokeWidth: stroke.width,
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
      })

      // Fix offset caused by the stroke width
      const offsetY = (textObject.getScaledHeight() - this.textObject.getScaledHeight()) / 2
      const top = objectPoint.y - offsetY
      const left = objectPoint.x
      textObject.set({
        top,
        left
      })

      this.strokes.push(textObject)
      this.page.document.add(textObject)
      this.page.document.canvas.bringObjectToFront(this.textObject)
    })
  }

  updateShadows(){
    this.shadows.forEach(entry => {
      this.page.document.remove(entry)
    })
    this.shadows = []
    
    // Apply group tranform if object is inside a selection
    const objectTransform = util.saveObjectTransform(this.textObject)
    let objectPoint = new Point(objectTransform.left, objectTransform.top)
    if (this.page.document.selection) {
      objectPoint = objectPoint.transform(this.page.document.selection.calcOwnMatrix())
    }

    this.styles.shadows.forEach(async (shadow) => {
      const textObject = await this.textObject.clone()
      const scale = this.textObject.getObjectScaling()
      textObject.set({
        selectable: false,
        shadow,
        scaleX: scale.x,
        scaleY: scale.y
      })

      const offsetY = (textObject.getScaledHeight() - this.textObject.getScaledHeight()) / 2
      const top = objectPoint.y - offsetY
      const left = objectPoint.x
      textObject.set({
        top,
        left
      })

      this.shadows.push(textObject)
      this.page.document.add(textObject)
      this.page.document.canvas.bringObjectToFront(this.textObject)
    })
  }

  getCanvasScale(){
    return this.page.document.width / 394
  }

  updateEffects(){
    this.updateShadows()
    this.updateStrokes()
  }

  forEachCharStyle(callback: (charStyle: CharacterTextStylesEntry) => void) {
    this.charStyles.forEach(charStyle => {
      callback(charStyle)
    })
  }

  resetScale(){
    this.textObject.scale(1)
    this.onChange()
  }

  applyTransforms(){
    this.styles.transforms.forEach(transform => {
      if (transform.type === 'rotation') {
        this.textObject.rotate(transform.value)
      }
    })
  }

  saveCharacterStyles({ styles, selectionStart, selectionEnd }: { styles: TextStylesChange, selectionStart: number, selectionEnd: number }){
    const indices: number[] = []
    for(let i=selectionStart; i<selectionEnd; i++){
      indices.push(i)
    }
    const newStyleEntry: CharacterTextStylesEntry = {
      indices,
      styles
    }
    this.charStyles.push(newStyleEntry)
    this.charStyles = mergeCharacterTextStyles(this.charStyles)
  }

  findStylesAtCharIndex(charIndex: number){
    return this.charStyles.find(entry => {
      return entry.indices.includes(charIndex)
    })
  }

  applyCharacterStyles(){
    let styles: FabricTextStyle = {}

    this.charStyles.forEach(charStyle => {
      charStyle.indices.forEach(globalCharIndex => {
        // Find at which line the char is
        const sliceWithChar = this.textObject.text.slice(0, globalCharIndex)
        const splitLines = sliceWithChar.split('\n')
        const lineIndex = splitLines.length - 1
        // Find at index the char is for this particular line
        let charsBeforeLine = 0
        splitLines.forEach((line, index) => {
          if (index < lineIndex - 1) charsBeforeLine += line.length
        })
        const charIndex = globalCharIndex - charsBeforeLine
        // Create an entry with the svgStyle object as the value
        const css = charStyle.styles.props?.fontVariant ? getFontCSS(charStyle.styles.props.fontVariant as FontData) : null
        const fontSize = charStyle.styles.props?.fontSize
        const svgStyles: Partial<CompleteTextStyleDeclaration> = {}
        if(css && css['font-family']) svgStyles.fontFamily = css['font-family']
        if(css && css['font-weight']) svgStyles.fontWeight = css['font-weight'].toString()
        if(css && css['font-style']) svgStyles.fontStyle = css['font-style']
        if(fontSize) svgStyles.fontSize = fontSize
        // @ts-expect-error Assumes getSVGFill will always return a valid gradient
        if(charStyle.styles.props.fill) svgStyles.fill = this.getSVGFill(charStyle.styles.props.fill)
        const styleUpdate = {
          [lineIndex]: {
            [charIndex]: svgStyles
          }
        }
        styles = deepmerge(styles, styleUpdate)
      })
    })

    this.textObject.styles = styles
  }

  getSVGFill(fill: string | Gradient){
    return typeof fill !== 'string' ? getAbsoluteGradient(this.textObject, fill) : fill
  }

  applyStyles(styles: TextStylesChange, updateDB = true, firstDraw=false){
    const css = styles.props?.fontVariant ? getFontCSS(styles.props.fontVariant as FontData) : null
    const fontSizeChanged = styles.props?.fontSize ? styles.props.fontSize !== this.styles.props.fontSize : false
    
    // Find if there are any style changes
    const fontSize = styles.props?.fontSize
    const svgStyles: Partial<SVGTextProps> = {}
    if(css && css['font-family']) svgStyles.fontFamily = css['font-family']
    if(css && css['font-weight']) svgStyles.fontWeight = css['font-weight']
    if(css && css['font-style']) svgStyles.fontStyle = css['font-style']
    if(fontSize) svgStyles.fontSize = fontSize
    if(styles.props?.lineHeight) svgStyles.lineHeight = styles.props.lineHeight
    if(styles.props?.fill) svgStyles.fill = this.getSVGFill(styles.props.fill)
    if(styles.props?.textAlign) svgStyles.textAlign = styles.props.textAlign
    if(styles.props?.letterSpacing && fontSize) svgStyles.charSpacing = styles.props.letterSpacing * fontSize * 32
    
    // Apply uppercase
    const shouldConvertToLowercase = styles.props?.uppercase ? (!styles.props.uppercase && this.styles?.props.uppercase) : false
    const selectionStart = this.textObject.selectionStart
    const selectionEnd = this.textObject.selectionEnd
    let text = this.textObject.text
    let shouldUpdateCase = false
    if(styles.props?.uppercase){
      text = this.textObject.text.toUpperCase()
      shouldUpdateCase = true
    }else if(shouldConvertToLowercase){
      text = this.textObject.text.toLowerCase()
      shouldUpdateCase = true
    }
    // Update textObject text, Editor object text
    this.textObject.set({ text })
    this.text = text
    // Restore cursor position after replacing text
    if(shouldUpdateCase){
      this.textObject.selectionStart = selectionStart
      this.textObject.selectionEnd = selectionEnd
      if(this.textObject.hiddenTextarea){
        this.textObject.hiddenTextarea.value = text
        this.textObject.hiddenTextarea.selectionStart = selectionStart
        this.textObject.hiddenTextarea.selectionEnd = selectionEnd
      }
    }
    
    // Apply text align if any
    if(styles.props?.textAlign){
      this.textObject.set({
        textAlign: styles.props.textAlign
      })
    }
    
    // Apply styles on text selection if any
    const hasSelection = this.textObject.selectionStart !== this.textObject.selectionEnd
    if(hasSelection){
      if(svgStyles.lineHeight){
        this.textObject.set({ lineHeight: svgStyles.lineHeight })
        this.styles.props.lineHeight = svgStyles.lineHeight
      }
      if(svgStyles.charSpacing && styles.props?.letterSpacing){
        this.textObject.set({ charSpacing: svgStyles.charSpacing })
        this.styles.props.letterSpacing = styles.props.letterSpacing
      }
      // Applies changes on Canvas
      this.textObject.setSelectionStyles(svgStyles)
      // Saves changes in instance
      this.saveCharacterStyles({
        styles,
        selectionStart: this.textObject.selectionStart,
        selectionEnd: this.textObject.selectionEnd
      })
    // Otherwise, apply styles on whole textObject
    }else{
      this.textObject.set(svgStyles)
      this.styles = {
        path: styles.path ?? this.styles.path,
        meta: styles.meta ?? this.styles.meta,
        props: {
          fill: styles.props?.fill ?? this.styles.props.fill,
          fontFamily: styles.props?.fontFamily ?? this.styles.props.fontFamily,
          fontVariant: styles.props?.fontVariant ?? this.styles.props.fontVariant,
          fontSize: styles.props?.fontSize ?? this.styles.props.fontSize,
          letterSpacing: styles.props?.letterSpacing ?? this.styles.props.letterSpacing,
          lineHeight: styles.props?.lineHeight ?? this.styles.props.lineHeight,
          stroke: styles.props?.stroke ?? this.styles.props.stroke,
          strokeWidth: styles.props?.strokeWidth ?? this.styles.props.strokeWidth,
          textAlign: styles.props?.textAlign ?? this.styles.props.textAlign,
          uppercase: styles.props?.uppercase ?? this.styles.props.uppercase
        },
        shadows: styles.shadows ?? this.styles.shadows,
        strokes: styles.strokes ?? this.styles.strokes,
        transforms: styles.transforms ?? this.styles.transforms,
      }


      if(!firstDraw){
        // Create the charStyle update to be applied 
        const chatStyleUpdate: Partial<CharacterTextStylesEntry['styles']['props']> = {}
        if (styles.props?.fill) chatStyleUpdate.fill = styles.props?.fill
        if (styles.props?.fontFamily) chatStyleUpdate.fontFamily = styles.props?.fontFamily
        if (styles.props?.fontVariant) chatStyleUpdate.fontVariant = styles.props?.fontVariant
        if (styles.props?.fontSize) chatStyleUpdate.fontSize = styles.props?.fontSize
        if (styles.props?.letterSpacing) chatStyleUpdate.letterSpacing = styles.props?.letterSpacing
        if (styles.props?.lineHeight) chatStyleUpdate.lineHeight = styles.props?.lineHeight
        if (styles.props?.stroke) chatStyleUpdate.stroke = styles.props?.stroke
        if (styles.props?.strokeWidth) chatStyleUpdate.strokeWidth = styles.props?.strokeWidth
        if (styles.props?.textAlign) chatStyleUpdate.textAlign = styles.props?.textAlign
        if (styles.props?.uppercase) chatStyleUpdate.uppercase = styles.props?.uppercase

        // Merge each charStyle with the style update
        this.forEachCharStyle(charStyle => {
          charStyle.styles.props = {
            ...charStyle.styles.props,
            ...chatStyleUpdate
          }
        })
      }
    }
    this.textObject.updateFromTextArea()

    // Apply char styles if any
    if(this.charStyles.length > 0){
      this.applyCharacterStyles()
    }

    if(fontSizeChanged) this.fixOutOfBoundsWidth()
    this.applyTransforms()
    
    setTimeout(() => {
      this.onUpdate()
    }, 100)

    if(updateDB && !this.settings.readOnly){
      this.save()
    }
  }

  resize(){
    this.textObject.set(this.getBoundingbox())
    this.onUpdate()
  }

  remove() {
    const objectsToCleanup = [
      this.textObject,
      ...this.strokes,
      ...this.shadows
    ]
    this.page.document.canvas.remove(...objectsToCleanup)
    this.page.document.canvas.discardActiveObject()
  }

  updateTransforms(){
    if(this.textObject.angle !== 0){
      this.styles.transforms = [
        {
          type: 'rotation',
          value: this.textObject.angle
        }
      ]
    }
  }

  fixOutOfBoundsWidth(){
    const [tl, tr] = this.textObject.getCoords()
    const textIsOutOfBounds = tl.x < 0 || tr.x > this.page.document.canvas.width
    if(textIsOutOfBounds){
      this.textObject.set({
        width: this.lastWidth
      })
    }
  }

  constrainToBounds(){
    const [tl,, br,] = this.textObject.getCoords()
    const documentWidth = this.page.document.canvas.width
    const documentHeight = this.page.document.documentHeight
    const position = {
      top: tl.y < 0 ? 0 : tl.y,
      left: tl.x < 0 ? 0 : tl.x,
    }
    if(br.x > documentWidth){
      position.left = documentWidth - this.textObject.getScaledWidth()
    }
    if(br.y > documentHeight){
      position.top = documentHeight - this.textObject.getScaledHeight()
    }
    this.textObject.set(position)
  }

  onFirstDraw(){
    this.applyStyles(this.styles, false, true)
    this.textObject.set(this.getBoundingbox())
    setTimeout(() => {
      this.onUpdate()
    }, 100)
  }

  onUpdate(){
    this.updateTransforms()
    this.updateEffects()
    this.page.document.render()
  }

  onSelected(){
    if(!this.skipSelectionEvent){
      updateStyles(this.styles, { updateSelected: false, updateDB: false })
      highlightTypesetText(this, { scrollView: 'sideview' })
      if (this.translation) {
        highlightTranslation(this.translation)
      }
    }else{
      this.skipSelectionEvent = true
    }
  }

  onUnselected(){
    unselectTypesetText(this)
  }
  
  onMouseUp(){
    this.onChange()
    const shouldUpdateDB = !this.lastPosition.eq(this.boundingBox.startPoint) && !this.settings.readOnly
    if(shouldUpdateDB){
      this.save()
    }
    this.lastPosition = this.boundingBox.startPoint.clone()
  }

  onChange(){
    this.boundingBox = this.getDBBoundingBox()
    this.onUpdate()
    updateTypesetText(this)
  }

  onResize(){
    this.lastWidth = this.textObject.width
    this.onChange()
  }

  onSelection(){
    // Update current style in style panel :
    // - with character styles at current selectionStart if any
    // - with textObject styles by default
    const charStylesEntry = this.findStylesAtCharIndex(this.textObject.selectionStart)
    const styles = charStylesEntry ? charStylesEntry.styles : this.styles
    updateStyles(styles, { updateSelected: false, updateDB: false })
  }

  onFocus(){
    this.textObject.hiddenTextarea?.addEventListener('input', () => this.applyStyles(this.styles, false))
    this.textObject.hiddenTextarea?.addEventListener('keydown', () => this.onSelection())
    this.textObject.hiddenTextarea?.addEventListener('focus', () => this.onSelection())
    this.page.document.hasFocus = false
  }
  
  onBlur(){
    this.textObject.hiddenTextarea?.removeEventListener('input', () => this.applyStyles(this.styles, false))
    this.textObject.hiddenTextarea?.removeEventListener('keydown', () => this.onSelection())
    this.textObject.hiddenTextarea?.removeEventListener('focus', () => this.onSelection())
    this.page.document.hasFocus = true
    this.save()
  }

  onDelete(){
    if(!this.settings.readOnly){
      deleteTypesetText(this, {
        database: true,
        view: true,
        canvas: true,
        history: this.createHistoryEntryOnDelete
      })
    }
  }

  save(){
    updateTypesetTextInDB(this)
  }

  registerEventListeners(){
    this.textObject.on('selected', () => this.onSelected())
    this.textObject.on('deselected', () => this.onUnselected())
    this.textObject.on('scaling', () => this.onChange())
    this.textObject.on('changed', () => this.onChange())
    this.textObject.on('resizing', () => this.onResize())
    this.textObject.on('rotating', () => this.onChange())
    this.textObject.on('moving', () => this.onChange())
    this.textObject.on('removed', (e) => { if(e.target instanceof Canvas) this.onDelete() })
    this.textObject.on('mouseup', () => this.onMouseUp())
    this.textObject.on('editing:entered', () => this.onFocus())
    this.textObject.on('editing:exited', () => this.onBlur())
  }
}

export default TypesetText