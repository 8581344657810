import { BoundingBox } from '~/types/comic/geometry'
import { Rect, FabricText, Group } from 'fabric'
import { colors } from '~/editor/design-tokens'
import { indexRectStyle } from '~/editor/translation/translation'

const getIndexDimensions = (pageWidth: number) =>{
  let size = indexRectStyle.size * pageWidth
  if (size > indexRectStyle.maxSize) size = indexRectStyle.maxSize
  if (size < indexRectStyle.minSize) size = indexRectStyle.minSize
  const fontSize = size * 0.5
  return {
    width: size,
    height: size,
    fontSize
  }
}

const getIndexedTranslation = (boundingBox: BoundingBox, index: number, pageWidth: number) => {
  const { fontSize, height, width } = getIndexDimensions(pageWidth)
  const container = new Rect({
    width: boundingBox.width,
    height: boundingBox.height,
    fill: 'transparent',
    stroke: colors.box.default,
    strokeWidth: 2,
    opacity: 0.5
  })
  const indexRect = new Rect({
    width,
    height,
    fill: colors.box.default,
    opacity: 0.5
  })
  const indexText = new FabricText((index + 1).toString(), {
    top: height / 2 - fontSize / 2,
    left: width / 2 - fontSize / 2,
    fontWeight: 'bold',
    fontFamily: 'Arial',
    fontSize: fontSize,
    fill: colors.box.text
  })
  indexText.setX(width / 2 - indexText.getScaledWidth() / 2)
  const indexGroup = new Group([
    indexRect,
    indexText
  ], {
    top: -height
  })

  const group = new Group([
    container,
    indexGroup
  ], {
    top: boundingBox.top - height,
    left: boundingBox.left,
  })
  return group
}

export {
  getIndexedTranslation
}