import { CharacterTextStylesEntry, TextStylesChange } from '~/types/editor/text-styles'

/* 
  Since the last CharacterTextStylesEntry is the one the user applied
  when can rely on the order to overwrite each style with the next

  - store each index after its TextStyles object is created, so we can skip it if we encounter it later (all styles are already merged into the TextStyles object)
  - find all entries in newCharStyles that have the same `styles` definition, and recreate a single one with all the indices in the indices array
*/

type CharacterTextStylesEntryWSignature = CharacterTextStylesEntry & {
  signature: string
}

const mergeCharacterTextStyles = (
  charStyles: CharacterTextStylesEntry[],
) => {
  const updatedCharStyles: CharacterTextStylesEntryWSignature[] = []
  const mergedCharStyles: CharacterTextStylesEntry[] = []
  const mergedIndices: number[] = []

  // Create the new merged TextStyles objects for each char
  charStyles.forEach(styleEntry => {
    styleEntry.indices.forEach(index => {
      // Skip if already done
      if (mergedIndices.includes(index)) return

      // Find all styles that are applied to this index
      const stylesAppliedToIndex = charStyles
        .filter(style => style.indices.includes(index))
        .map(charStyle => charStyle.styles)

      // Merge them to a TextStyles object
      const mergedStyles: TextStylesChange = stylesAppliedToIndex.reduce((finalStyles, currentStyles) => {
        return finalStyles = {
          props: {
            ...finalStyles.props,
            ...currentStyles.props
          }
        }
      })

      // Mark index as [done]
      mergedIndices.push(index)
      // Create an entry with only the styles for that char
      updatedCharStyles.push({
        indices: [index],
        styles: mergedStyles,
        signature: JSON.stringify(mergedStyles)
      })
    })
  })

  // Find and merge the chars that have the same TextStyles
  updatedCharStyles.forEach(currentCharStyle => {
    const alreadyExists = mergedCharStyles.find(entry => JSON.stringify(entry.styles) === currentCharStyle.signature)
    if (alreadyExists) return

    const identicEntries = updatedCharStyles.filter(entry => {
      return currentCharStyle.signature === entry.signature
    })
    mergedCharStyles.push({
      indices: identicEntries.map(entry => entry.indices).flat(),
      styles: currentCharStyle.styles
    })
  })

  return mergedCharStyles
}

export {
  mergeCharacterTextStyles
}