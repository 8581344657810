import { createSignal } from 'solid-js'
import { history } from '~/editor/history'
import { FontData, FontFamily } from '~/local-fonts'
import { selectedTypesetTexts } from '~/services/current-document/typeset-texts'
import { Shadow, Stroke, TextStyles, TextStylesChange, Transform, UpdateStylesOptions } from '~/types/editor/text-styles'

const defaultStyles: TextStyles = {
  path: 'Style par défaut',
  props: {
    fontFamily: {
      name: 'Comic Neue',
      styles: [{
        family: 'Comic Neue',
        fullName: 'Comic Neue Bold',
        postscriptName: 'Comic-Neue-Bold',
        style: 'Bold'
      }]
    },
    fontVariant: {
      family: 'Comic Neue',
      fullName: 'Comic Neue Bold',
      postscriptName: 'Comic-Neue-Bold',
      style: 'Bold'
    },
    textAlign: 'center',
    fontSize: 24,
    lineHeight: 1,
    letterSpacing: 0,
    fill: '#000000',
    stroke: 'none',
    strokeWidth: 0,
    uppercase: true
  },
  strokes: [],
  transforms: [],
  shadows: []
}

const [textStyles, setTextStyles] = createSignal<TextStyles>(defaultStyles, { equals: false })

const updateStyles = (update: TextStylesChange, options: UpdateStylesOptions = { updateSelected: true, updateDB: true, resetCharStyles: false }) => {
  // Update Editor panel styles
  const styles = textStyles()
  const fontFamily = update.props?.fontFamily as FontFamily
  const fontVariant = update.props?.fontVariant as FontData
  const strokes = (update.strokes ?? styles.strokes) as Stroke[]
  const shadows = (update.shadows ?? styles.shadows) as Shadow[]
  const transforms = (update.transforms ?? styles.transforms) as Transform[]
  const newStyles: TextStyles = {
    path: update.path ?? null,
    props: {
      ...styles.props,
      ...update.props,
      fontFamily: fontFamily ?? styles.props.fontFamily,
      fontVariant: fontVariant ?? styles.props.fontVariant
    },
    strokes,
    shadows,
    transforms
  }
  setTextStyles(newStyles)

  // Update TypesetTextEntry styles
  const updateSelected = options.updateSelected ?? true
  const updateDB = options.updateDB ?? true
  
  if(updateSelected){
    selectedTypesetTexts().forEach(entry => {
      const oldStyles = entry.styles

      if (updateDB){
        history.append({
          name: 'updateTypesetText',
          undo: () => {
            entry.applyStyles(oldStyles, true)
          },
          redo: () => {
            entry.applyStyles(newStyles, true)
          }
        })
      }

      entry.applyStyles(update, updateDB)
    })
  }

  console.trace()
}

type TextStyleUpdater = typeof updateStyles

export {
  textStyles,
  updateStyles,
  defaultStyles,
  TextStyleUpdater
}