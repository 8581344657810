import { v4 as uuidv4 } from 'uuid'
import { StaticCanvas, Point, FabricImage } from 'fabric'
import { BoundingBox } from '~/types/comic/geometry'
import { dbRowToTranslationVersion } from '~/helpers/database-conversions'
import { getIndexedTranslation } from '~/server/export/translation/indexed-translation'
import { IndexedTranslation } from '~/server/export/translation/chapter-translations'

const getIndexedPage = async (image: HTMLImageElement, translations: IndexedTranslation[], exportWidth = 512) => {
  const resizeFactor = exportWidth / image.width
  const dimensions = {
    width: exportWidth,
    height: image.height * resizeFactor,
  }

  const canvas = new StaticCanvas(`page-${uuidv4()}`, {
    ...dimensions
  })

  const page = new FabricImage(image, {
    top: 0,
    left: 0,
    scaleX: resizeFactor,
    scaleY: resizeFactor,
  })
  canvas.add(page)

  translations.forEach(entry => {
    const pageWidth = page.getScaledWidth()
    const pageHeight = page.getScaledHeight()
    const translation = dbRowToTranslationVersion(entry)
    // Exported translations must have valid Points, so we clarify their type can't be null
    const startPoint = translation.startPoint as Point
    const endPoint = translation.endPoint as Point

    const boundingBox: BoundingBox = {
      left: Math.min(startPoint.x, endPoint.x) * pageWidth,
      top: page.top + Math.min(startPoint.y, endPoint.y) * pageHeight,
      width: Math.abs(startPoint.x - endPoint.x) * pageWidth,
      height: Math.abs(startPoint.y - endPoint.y) * pageHeight,
    }
    const translationObject = getIndexedTranslation(boundingBox, entry.index, dimensions.width)
    canvas.add(translationObject)
  })

  return {
    dimensions,
    dataURL: canvas.toDataURL()
  }
}

export {
  getIndexedPage
}