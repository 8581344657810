import { Component, createSignal, Show } from 'solid-js'
import { SubmitHandler, createForm, valiForm } from '@modular-forms/solid'
import styles from '~/components/forms/styles/simple-form.module.scss'
import TextField from '~/components/fields/text/text'
import Button from '~/components/button/button'
import { loginUser, registerUser } from '~/services/database/users'
import { RegisterSchema, RegisterFormType, RegisterError, getError } from '~/components/forms/account/register-form/register-form.interfaces'
import { useNavigate } from '@solidjs/router'
import { A } from '@solidjs/router'
import ConfirmationModal from '~/components/confirmation-modal/confirmation-modal'
import CloseIcon from '~/assets/icons/close.svg'
import { translations } from '~/translations'

const RegisterForm: Component = () => {
  const navigate = useNavigate()
  const [error, setError] = createSignal<RegisterError>()
  const [showError, setShowError] = createSignal(false)
  const [form, { Form, Field }] = createForm<RegisterFormType>({
    validate: valiForm(RegisterSchema)
  })
  const onSubmit: SubmitHandler<RegisterFormType> = async (values) => {
    const result = await registerUser({
      email: values.email,
      password: values.password,
      name: values.name,
    })
    if (result.error){
      const error = getError(result.error.code)
      setError(error)
      setShowError(true)
    }else{
      await loginUser({
        email: values.email,
        password: values.password
      })
      navigate('/')
    }
  }

  return (
    <>
      <Show when={error()}>
        {(error) => (
          <ConfirmationModal
            open={showError()}
            setOpen={() => setShowError(false)}
            onConfirm={() => setShowError(false)}
            title={error().title}
            description={error().description}
            confirmLabel='Fermer'
            confirmIcon={CloseIcon}
            confirmOnly
          />
        )}
      </Show>
      <div class={styles.container}>
        <div class={styles.title}>
          {translations().register.title}
        </div>
        <Form onSubmit={onSubmit} class={styles.form}>
          <Field name="name">
            {(field, props) => (
              <TextField
                label={translations().account.profile.firstName}
                placeholder={translations().account.profile.firstName}
                autocomplete='name'
                {...field}
                {...props}
              />
            )}
          </Field>
          <Field name="email">
            {(field, props) => (
              <TextField
                label={translations().account.profile.email}
                type='email'
                placeholder={translations().login.emailPlaceholder}
                autocomplete='email'
                {...field}
                {...props}
              />
            )}
          </Field>
          <Field name="password">
            {(field, props) => (
              <TextField
                label={translations().register.password}
                type='password'
                autocomplete='new-password'
                {...field}
                {...props}
              />
            )}
          </Field>
          <Button
            type='submit'
            style='lagoon'
            isLoading={form.submitting}
          >
            {translations().register.register}
          </Button>
        </Form>
        <A href='/account/login' class={styles.link}>
          {translations().register.alreadyHaveAccount}
        </A>
      </div>
    </>
  )
}

export default RegisterForm
