import { colors, fontFamily } from '~/editor/design-tokens'
import { TranslationProps, TranslationVersion } from '~/editor/translation/translation.interfaces'
import { reorderByPosition, deleteTranslation, updateTranslation, highlightTranslation } from '~/services/current-document/translations'
import { BoundingBox } from '~/types/comic/geometry'
import { FabricText, Group, Point, Rect, util } from 'fabric'
import Page from '~/editor/page/page'
import { DocumentProps } from '~/editor/document/document.interfaces'
import { Comment } from '~/types/comic/comments'
import { highlightTypesetText } from '~/services/current-document/typeset-texts'
import TypesetText from '~/editor/typeset-text/typeset-text'

export const indexRectStyle = {
  size: 0.05,
  minSize: 20,
  maxSize: 32
}

class Translation{
  id: string
  index: number
  versions: TranslationVersion[]
  comments: Comment[]
  group: Group
  startPoint: Point | null
  endPoint: Point | null
  sideviewElementRef: HTMLDivElement | undefined 
  originalText: string
  originalTextRef: HTMLTextAreaElement | undefined
  translatedText: string
  translatedTextRef: HTMLTextAreaElement | undefined
  date: string
  isCorrection: boolean
  isOnomatopoeia: boolean
  userId: string | null
  boundingBox: BoundingBox
  container: Rect
  indexGroup: Group
  indexRect: Rect
  indexText: FabricText
  highlighted: boolean
  page: Page
  createHistoryEntryOnDelete: boolean
  updateDBOnDelete: boolean
  skipFocusEvent: boolean
  settings: DocumentProps['settings']
  typesetText: TypesetText | null
  
  constructor({ id, versions, page, comments=[], index=0 }: TranslationProps){
    this.id = id
    this.index = index
    this.page = page
    this.versions = versions
    const lastVersion = this.versions[this.versions.length-1]
    this.comments = comments
    this.startPoint = lastVersion.startPoint
    this.endPoint = lastVersion.endPoint
    this.originalText = lastVersion.originalText
    this.translatedText = lastVersion.translatedText
    this.date = lastVersion.date
    this.isCorrection = Boolean(lastVersion.isCorrection)
    this.isOnomatopoeia = Boolean(lastVersion.isOnomatopoeia)
    this.userId = lastVersion.userId
    this.highlighted = false
    this.createHistoryEntryOnDelete = true
    this.updateDBOnDelete = true
    this.skipFocusEvent = false
    this.settings = page.document.settings
    this.typesetText = null

    // First draw
    const color = this.getMainColor()
    this.boundingBox = this.getBoundingBox()
    this.container = new Rect({
      width: this.boundingBox.width,
      height: this.boundingBox.height,
      fill: 'transparent',
      stroke: color,
      strokeWidth: 2,
      opacity: 0.5
    })
    
    this.indexRect = new Rect({
      width: this.getIndexDimensions().width,
      height: this.getIndexDimensions().height,
      fill: color,
      opacity: 0.5
    })

    this.indexText = new FabricText((this.index + 1).toString(), {
      top: this.getIndexDimensions().height / 2 - this.getIndexDimensions().fontSize / 2,
      left: this.getIndexDimensions().width / 2 - this.getIndexDimensions().fontSize / 2,
      fontWeight: 'bold',
      fontFamily: fontFamily.primary,
      fontSize: this.getIndexDimensions().fontSize,
      fill: colors.box.text,
    })

    this.indexGroup = new Group([
      this.indexRect,
      this.indexText,
    ], {
      top: this.boundingBox.top - this.getIndexDimensions().height,
      left: this.boundingBox.left,
      selectable: false
    })

    const transformProps = this.settings.readOnly ? {
      lockScalingX: true,
      lockScalingY: true,
      lockMovementX: true,
      lockMovementY: true
    } : null

    this.group = new Group([
      this.container
    ], {
      ...this.boundingBox,
      ...transformProps,
      visible: Boolean(this.startPoint && this.endPoint),
      lockRotation: true
    })
    
    if(this.settings.readOnly){
      this.group.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false,
        mtr: false
      })
    }

    this.registerEventListeners()
  }

  getIndexDimensions(){
    let size = indexRectStyle.size * this.page.image.getScaledWidth()
    if (size > indexRectStyle.maxSize) size = indexRectStyle.maxSize
    if (size < indexRectStyle.minSize) size = indexRectStyle.minSize
    const fontSize = size * 0.5
    return {
      width: size,
      height: size,
      fontSize
    }
  }

  getBoundingBox(){
    let boundingBox: BoundingBox = {
      left: 0,
      top: 0,
      width: 0,
      height: 0
    }

    if(this.startPoint && this.endPoint){
      const pageTop = this.page.image.top
      const pageWidth = this.page.image.getScaledWidth()
      const pageHeight = this.page.image.getScaledHeight()
      boundingBox = {
        left: Math.min(this.startPoint.x, this.endPoint.x) * pageWidth,
        top: pageTop + Math.min(this.startPoint.y, this.endPoint.y) * pageHeight,
        width: Math.abs(this.startPoint.x - this.endPoint.x) * pageWidth,
        height: Math.abs(this.startPoint.y - this.endPoint.y) * pageHeight,
      }
    }
    
    return boundingBox
  }

  getMainColor(){
    let color = colors.box.default
    if (this.isOnomatopoeia) {
      color = colors.box.onomatopoeia
    }
    if (this.isCorrection) {
      color = colors.box.correction
    }
    if (this.highlighted) {
      color = colors.box.selected
    }
    return color
  }

  update(){
    this.indexText.set('text', (this.index+1).toString())
    const textX = this.group.left + 1 + this.getIndexDimensions().width / 2 - this.indexText.getScaledWidth() / 2
    this.indexText.setX(textX)

    const color = this.getMainColor()
    this.indexRect.set('fill', color)
    this.container.set('stroke', color)
  }

  onSelected(){
    this.page.document.hasFocus = true
    highlightTranslation(this, { scrollView: 'sideview' })
    if(this.typesetText){
      highlightTypesetText(this.typesetText, { scrollView: 'sideview', focusField: true })
    }
  }

  onUnselected(){
    this.highlighted = false
    this.update()
  }

  // Recomputes relative percentage based startPoint & endPoint
  updatePoints(){
    if (this.startPoint && this.endPoint){
      const pageTop = this.page.image.top
      const coords = {
        topX: this.group.left / this.page.image.getScaledWidth(),
        topY: (this.group.top - pageTop) / this.page.image.getScaledHeight(),
        bottomX: (this.group.left + this.group.getScaledWidth()) / this.page.image.getScaledWidth(),
        bottomY: ((this.group.top - pageTop) + this.group.getScaledHeight()) / this.page.image.getScaledHeight()
      }
      this.startPoint.setXY(coords.topX, coords.topY)
      this.endPoint.setXY(coords.bottomX, coords.bottomY)
    }
  }

  // Runs on scale or browser resize
  onTransform(){
    this.updatePoints()
    this.resize()
  }

  resize(){
    this.boundingBox = this.getBoundingBox()
    this.group.set({
      ...this.boundingBox,
      scaleX: 1,
      scaleY: 1
    })
    this.group.setCoords()
    this.indexGroup.set({
      top: this.boundingBox.top - this.getIndexDimensions().height,
      left: this.boundingBox.left
    })
    this.indexGroup.setCoords()
    this.indexRect.set({
      width: this.getIndexDimensions().width,
      height: this.getIndexDimensions().height
    })
    this.indexRect.setCoords()
    this.indexText.set({
      fontSize: this.getIndexDimensions().fontSize,
    })
    this.container.set({
      left: - this.boundingBox.width / 2,
      top: - this.boundingBox.height / 2,
      width: this.boundingBox.width,
      height: this.boundingBox.height,
    })
    this.container.setCoords()
    this.onMove()
  }

  updateTransform(){
    this.updatePoints()
    this.resize()
    updateTranslation(this.id, {
      startPoint: this.startPoint,
      endPoint: this.endPoint
    })
  }

  onMove(){
    reorderByPosition()
    const objectTransform = util.saveObjectTransform(this.group)
    const objectPoint = new Point(objectTransform.left, objectTransform.top)
    this.indexGroup.set({
      top: objectPoint.y - this.getIndexDimensions().height,
      left: objectPoint.x
    })
  }

  onDelete(){
    this.page.document.remove(this.indexGroup)
    if(this.updateDBOnDelete){
      if(this.settings.mode === 'translation'){
        deleteTranslation(this, {
          database: true,
          view: true,
          history: this.createHistoryEntryOnDelete
        })
      }
    }
  }

  registerEventListeners(){
    this.group.on('selected', () => this.onSelected())
    this.group.on('deselected', () => this.onUnselected())
    this.group.on('removed', () => this.onDelete())
    if(!this.page.document.settings.readOnly){
      this.group.on('moving', () => this.onMove())
      this.group.on('mouseup', () => this.updateTransform())
    }
  }
}

export default Translation
