// @refresh reload
import * as Sentry from "@sentry/solid"
import { Link, Meta, MetaProvider, Title } from "@solidjs/meta"
import { Router } from "@solidjs/router"
import { FileRoutes } from "@solidjs/start/router"
import { ErrorBoundary, Suspense, createEffect } from "solid-js"
import "~/styles/reset.scss"
import "~/styles/fonts.scss"
import "~/styles/app.scss"
import "~/styles/darkmode.scss"
import "~/styles/sentry.scss"
import { settings, updateDarkmode } from '~/services/settings/settings'
import Layout from '~/components/layout/layout'
import FatalError from '~/components/fatal-error/fatal-error'

Sentry.init({
  dsn: "https://1d85594e6a7a16b426b5b627f3c5f337@o4507585500610560.ingest.de.sentry.io/4507585503363152",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration(),
  ],
  denyUrls: ['http://localhost:3000/'],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/beta-blackstudio-editor\.vercel\.app/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default function Root() {
  createEffect(() => {
    updateDarkmode(settings().darkmode)
  })

  const errorFallBack = (error: Error, reset: () => void) => {
    return (
      <FatalError
        error={error}
        reset={reset}
      />
    )
  }

  return (
    <Router
      root={props => (
        <MetaProvider>
          <Title>ZOON | Blackstudio</Title>
          <Meta charset="utf-8" />
          <Meta name="viewport" content="width=device-width, initial-scale=1" />
          <Link rel="icon" type="image/png" href="/assets/favicons/favicon-16.png" sizes="16x16" />
          <Link rel="icon" type="image/png" href="/assets/favicons/favicon-32.png" sizes="32x32" />
          <Link rel="shortcut icon" sizes="196x196" href="/assets/favicons/favicon-196.png" />
          <Link rel="apple-touch-icon" sizes="180x180" href="/assets/favicons/favicon-180.png" />
          <ErrorBoundary fallback={errorFallBack}>
            <Suspense>
              <Layout>
                  {props.children}
              </Layout>
            </Suspense>
          </ErrorBoundary>
        </MetaProvider>
      )}
    >
      <FileRoutes />
    </Router>
  )
}
